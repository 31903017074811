
/* ==========================================================================
   pages: Page
   ========================================================================== */

body.page {
  .head {
    text-align: center;

    @include mq(pc) {
      padding-top: 270px;
    }
    @include mq(sp) {
      padding-top: 136px;
    }

    .title {
      @include mq(pc) {
        margin-bottom: 40px;
      }
      @include mq(sp) {
        margin-bottom: 24px;

        svg {
          height: 27px;
          width: auto;
        }
      }
    }
    .lead {
      font-weight: 700;
      line-height: 1.5;
      transition-delay: .2s;

      @include mq(pc) {
        margin-bottom: 20px;
        font-size: 22px;
      }
      @include mq(sp) {
        margin-bottom: 15px;
        font-size: 17px;
      }
    }
    .txt {
      line-height: 1.7;
      transition-delay: .4s;

      @include mq(pc) {
        margin-bottom: 50px;
        font-size: 16px;
      }
      @include mq(sp) {
        margin-bottom: 26px;
      }
      &.txt-jp {
        line-height: 1.8;

        @include mq(pc) {
          font-size: 15px;
        }
        @include mq(sp) {
        }
      }
    }
    .ico {
      transition-delay: .6s;

      @include mq(pc) {
        margin-bottom: 74px;
      }
      @include mq(sp) {
        margin-bottom: 45px;

        svg {
          height: 49px;
          width: auto;
        }
      }
    }
    .img {
      @include mq(pc) {
        margin-bottom: 100px;
      }
      @include mq(sp) {
        margin-bottom: 55px;
      }

      position: relative;
    	width: 100%;
    	height: 100%;
    	padding: 67% 0 0;

      &_inner {
        position: absolute;
      	top: 0;
      	left: 0;
      	width: 100%;
      	height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      &_stay {
        background-image: url(/assets/img/stay/img_stay.jpg);
      }
      &_eat-drink {
        background-image: url(/assets/img/eat-drink/img_eat-drink.jpg);
      }
      &_facilities {
        background-image: url(/assets/img/facilities/img_facilities.png);
      }
    }
    #amap {
      @include mq(pc) {
        margin: 70px 0 100px;
        height: 60vw;
      }
      @include mq(sp) {
        margin: 32px 0 54px;
        height: 80vw;
      }
    }
  }

  h2.title {
    text-align: center;
    font-weight: 800;

    @include mq(pc) {
      margin-bottom: 70px;
      font-size: 48px;
    }
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 33px;
    }
  }
  h3.title {
    @include mq(pc) {
      font-weight: 700;
      font-size: 32px;
    }
    @include mq(sp) {
      font-weight: 800;
      font-size: 22px;
    }
    &.txt-jp {
      line-height: 1.3;

      @include mq(pc) {
        font-size: 33px;
      }
      @include mq(sp) {
        font-size: 23px;
      }
    }
  }
  h4.title {
    font-weight: 800;

    @include mq(pc) {
      margin-bottom: 15px;
      font-size: 24px;
    }
    @include mq(sp) {
      margin-bottom: 20px;
      font-size: 33px;
    }
  }
  .js-sp_swipe {
    @include mq(sp) {
      padding: 0 20px;
    }
  }
  .types {
    position: relative;
    display: flex;

    @include mq(pc) {
      margin-bottom: 95px;
    }
    @include mq(sp) {
      margin: 20px 0 45px;
      width: 100%;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #e0e0e0;
    }
    // &::after {
    //   @include mq(pc) {
    //     width: 25%;
    //     height: 2px;
    //     background: $c_yellow;
    //   }
    // }

    &_nav {
      position: relative;
      text-align: center;
      font-weight: 800;
      transition: .6s $Base;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        opacity: 0;
        background: $c_yellow;
        transition: .6s $Base;
      }
      &::before {
        @include mq(pc) {
          display: none;
        }
        @include mq(sp) {
          opacity: 1;
          background: #e0e0e0;
        }
      }
      &.active {
        &::after {
          height: 2px;
          opacity: 1;
        }
      }
      &:hover {
        color: $c_yellow;
      }

      @include mq(pc) {
        padding: 22px 10px;
        width: 25%;
        font-size: 15px;
      }
      @include mq(sp) {
        padding: 12px 16px 14px;
        font-size: 12px;
        width: auto;

        &:last-child {
          // padding-right: 20px;
        }
      }
    }
  }
  .js-tab-contents {
    overflow: hidden;
    height: 0;

    &.active {
      height: auto;
    }
  }

  .block {
    @include mq(pc) {
      display: flex;
      margin-bottom: 50px;
      flex-direction: row-reverse;
    }
    @include mq(sp) {
      margin-bottom: 50px;
      padding: 0 20px;
    }

    & + .notice {
      @include mq(pc) {
        margin-top: -10px;
      }
      @include mq(sp) {
        margin-top: -25px;
      }
    }

    &_img {
      @include mq(pc) {
        width: 50.5%;
      }
      @include mq(sp) {
        position: relative;
        width: calc(100vw - 20px);
        // left: -20px;
      }
      &.single {
        @include mq(sp) {
          width: 100%;
        }
      }
      .link {
        @include mq(sp) {
          margin-top: 28px;
          text-align: center;
        }
      }
    }
    &_txt {
      @include mq(pc) {
        flex: 1;
        padding-left: 52px;
      }
      h2.title {
        @include mq(pc) {
          margin-bottom: 24px;
          text-align: left;
        }
        @include mq(sp) {
          margin-bottom: 20px;
          text-align: center;
        }
      }
      h3.title {
        @include mq(pc) {
          margin-bottom: 20px;
        }
        @include mq(sp) {
          margin-bottom: 20px;
          text-align: center;
        }
      }
      h3.catch {
        line-height: 1.5;

        @include mq(pc) {
          margin-bottom: 15px;
          font-size: 24px;
        }
        @include mq(sp) {
          margin-bottom: 17px;
          font-size: 17px;
          text-align: center;
        }
      }
      .txt {
        @include mq(pc) {
          margin-bottom: 26px;
          line-height: 1.8;
        }
        @include mq(sp) {
          margin-bottom: 26px;
          line-height: 1.5;
        }
        &:last-child {
          @include mq(pc) {
            margin-bottom: 0;
          }
        }
        &.txt-jp {
          @include mq(sp) {
            font-size: 13px;
            line-height: 1.8;
          }
        }
      }
    }
    &_access {
      @include mq(pc) {
        margin-bottom: 180px;
        flex-direction: row;
      }
      @include mq(sp) {
        margin-bottom: 90px;
      }

      .block_img {
        @include mq(pc) {
          width: 50%;
          padding-left: 112px;
        }
        @include mq(sp) {
          padding-bottom: 25px;
          text-align: center;
          width: calc(100vw - 40px);
        }
      }
      .block_txt {
        @include mq(pc) {
          margin-top: -8px;
          padding-left: 30px;
        }

        h3.catch {
          @include mq(pc) {
            margin-bottom: 8px;
          }
          @include mq(sp) {
            margin: 25px 0 10px;
          }
        }
      }
    }
  }
  h2.sub-title {
    @include mq(pc) {
      text-align: left;
    }
    @include mq(sp) {
      margin-bottom: 40px;
    }
  }
  .sub-blocks {
    @include mq(pc) {
      display: flex;

      .block {
        flex-direction: column-reverse;
        margin-right: 50px;
        margin-bottom: 0;
        width: calc(50% - 25px);

        &:nth-child(even) {
          margin-right: 0;
        }
        &_img {
          margin-bottom: 28px;
          width: 100%;
        }
        &_txt {
          padding-left: 0;
          display: block;
          width: 100%;
        }
      }
    }
    @include mq(sp) {
      .title {
        margin-bottom: 15px;
        font-size: 22px;
        text-align: center;
      }
    }
  }

  .notice {
    background: #f5f5f5;

    @include mq(pc) {
      margin-bottom: 25px;
      padding: 42px 48px 40px;
    }
    @include mq(sp) {
      margin: 0 auto 16px;
      padding: 26px 20px 24px;
      width: calc(100% - 40px);
    }

    dl {
      &:last-child {
        margin-bottom: 0;
      }
      @include mq(pc) {
        margin-bottom: 28px;
        display: flex;
      }
      @include mq(sp) {
        margin-bottom: 22px;
      }

      dt {
        font-weight: 700;

        @include mq(pc) {
          width: 160px;
          font-size: 20px;
        }
        @include mq(sp) {
          margin-bottom: 10px;
          font-size: 17px;
        }
      }
      dd {
        line-height: 1.5;

        @include mq(pc) {
          flex: 1;
        }
        &.txt-jp {
          @include mq(pc) {
            font-size: 19px;
          }
          @include mq(sp) {
            font-size: 13px;
            line-height: 1.6;
          }
        }
      }
    }
  }

  .tripadvisor {
    text-align: right;

    @include mq(pc) {
      margin-bottom: 70px;
    }
    @include mq(sp) {
      margin-bottom: 55px;
      padding-right: 20px;
    }

    a {
      background: url(/assets/img/common/ico_tripadvisor.svg) no-repeat left center;

      @include mq(pc) {
        padding: 6px 0px 5px 45px;
        background-size: 34px auto;
        font-size: 15px;
      }
      @include mq(sp) {
        padding: 6px 0px 4px 38px;
        background-size: 26px auto;
        font-size: 15px;
      }
      &:hover {
        color: $c_yellow;
      }
    }
  }

  .aside.links {
    text-align: center;

    @include mq(pc) {
      margin-top: 130px;
      margin-bottom: 160px;
    }
    @include mq(sp) {
      margin-top: 90px;
      padding-bottom: 75px;
    }

    .link {
      @include mq(pc) {
        display: inline-block;
        margin: 0 12px;
        font-size: 12px;
      }
      @include mq(sp) {
        margin-bottom: 30px;
        padding: 0 20px;
        font-size: 11px;
      }

      .btn {
        @include mq(pc) {
          margin-bottom: 18px;
        }
        @include mq(sp) {
          margin-bottom: 12px;
        }
      }
    }
  }

  &.access {
    #fmap {
      // display: none;
      margin: 0;
      opacity: 0;

      @include mq(pc) {
        height: 70px;
      }
      @include mq(sp) {
        height: 35px;
      }
    }
  }

  .block_faq {
    display: block;

    @include mq(pc) {
      margin: 80px auto 0;
      padding-top: 78px;
      border-top: 2px solid #000;
      max-width: 600px;
    }
    @include mq(sp) {
      margin: 40px auto 0;
      padding: 36px 0 0;
      border-top: 1px solid #000;
      width: calc(100% - 36px);
    }

    h2.title {
      @include mq(pc) {
        margin-bottom: 50px;
      }
      @include mq(sp) {
        margin-bottom: 28px;
      }
    }

    .faq_item {
      &_q,
      &_a {
        display: flex;

        .txt {
          flex: 1;
        }
      }
      &_q {
        margin-bottom: 0.8em;

        @include mq(pc) {
          font-size: 20px;
          font-weight: 600;
        }
        @include mq(sp) {
          font-weight: 700;
        }

        .txt {
          letter-spacing: 0.03em;
          line-height: 1.5;
          vertical-align: text-bottom;

          &.jp {
            @include mq(pc) {
              font-size: 19px;
            }
          }
        }
        .ico {
          width: 1.3em;
          line-height: 1.5;
        }
      }
      &_a {
        @include mq(pc) {
          margin-bottom: 3em;
        }
        @include mq(sp) {
          margin-bottom: 2em;
        }

        .txt {
          margin-top: -3px;
          letter-spacing: 0.03em;
          line-height: 1.8;
          font-weight: 500;

          a {
            text-decoration: underline;
          }
        }
        .ico {
          color: $c_yellow;
          font-weight: 600;
          width: 1.3em;

          @include mq(pc) {
            font-size: 20px;
          }
        }
      }
    }
  }
}
