@charset "UTF-8";
/*
  Theme Name: kiokjp
  Theme URI: http://www.kiokjapan.jp/
  Author: KAJIL
  Author URI: https://kajil.co/
  Description:
  Version: 1.0.0
  License:
  License URI:
  Text Domain:
*/
/*
  Stylesheet Header ...
*/
/*--------------------
>>> TABLE OF CONTENTS:
----------------------
0.0 Vendor
1.0 Reset
2.0 Vars
3.0 Frame
4.0 Parts
5.0 Pages
6.0 Popup
--------------------*/
/*----- 0.0 Vendor -----*/
@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
/*----- 1.0 Reset -----*/
*,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
form,
dl,
dt,
dd,
div,
table,
tr,
th,
td {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
  font-size: 1em;
}

*:focus,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
p:focus,
ul:focus,
ol:focus,
li:focus,
form:focus,
dl:focus,
dt:focus,
dd:focus,
div:focus,
table:focus,
tr:focus,
th:focus,
td:focus {
  outline: none;
}

section,
div {
  margin: 0;
  padding: 0;
}

img,
figure,
iframe,
object {
  border: 0;
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

a {
  display: inline-block;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

ul,
ol,
li {
  list-style-type: none;
}

input, button, textarea, select, option {
  appearance: none;
}

input[type="text"],
input[type="email"],
textarea,
select,
option,
button {
  padding: 0;
  border: none;
  border-radius: 0;
  outline: none;
  background: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

label {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

button {
  cursor: pointer;
}

/*----- 2.0 Library & Fonts -----*/
/* ==========================================================================
   Vars
  ========================================================================== */
/* ==========================================================================
   Break
  ========================================================================== */
/* show control */
@media screen and (min-width: 769px) {
  .pcShow {
    display: block !important;
  }
  .spShow {
    display: none !important;
  }
  .pcShowIb {
    display: inline-block !important;
  }
  .spShowIb {
    display: none !important;
  }
  .pcShowFx {
    display: flex !important;
  }
  .spShowFx {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .pcShow {
    display: none !important;
  }
  .spShow {
    display: block !important;
  }
  .pcShowIb {
    display: none !important;
  }
  .spShowIb {
    display: inline-block !important;
  }
  .pcShowFx {
    display: none !important;
  }
  .spShowFx {
    display: flex !important;
  }
}

/* spaces */
.mgt0 {
  margin-top: 0px;
}

@media screen and (max-width: 768px) {
  .mgb_sp0 {
    margin-top: 0px !important;
  }
}

.mgt5 {
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .mgb_sp5 {
    margin-top: 5px !important;
  }
}

.mgt10 {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .mgb_sp10 {
    margin-top: 10px !important;
  }
}

.mgt15 {
  margin-top: 15px;
}

@media screen and (max-width: 768px) {
  .mgb_sp15 {
    margin-top: 15px !important;
  }
}

.mgt20 {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .mgb_sp20 {
    margin-top: 20px !important;
  }
}

.mgt25 {
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .mgb_sp25 {
    margin-top: 25px !important;
  }
}

.mgt30 {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .mgb_sp30 {
    margin-top: 30px !important;
  }
}

.mgt35 {
  margin-top: 35px;
}

@media screen and (max-width: 768px) {
  .mgb_sp35 {
    margin-top: 35px !important;
  }
}

.mgt40 {
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .mgb_sp40 {
    margin-top: 40px !important;
  }
}

.mgt45 {
  margin-top: 45px;
}

@media screen and (max-width: 768px) {
  .mgb_sp45 {
    margin-top: 45px !important;
  }
}

.mgt50 {
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .mgb_sp50 {
    margin-top: 50px !important;
  }
}

.mgt55 {
  margin-top: 55px;
}

@media screen and (max-width: 768px) {
  .mgb_sp55 {
    margin-top: 55px !important;
  }
}

.mgt60 {
  margin-top: 60px;
}

@media screen and (max-width: 768px) {
  .mgb_sp60 {
    margin-top: 60px !important;
  }
}

.mgt65 {
  margin-top: 65px;
}

@media screen and (max-width: 768px) {
  .mgb_sp65 {
    margin-top: 65px !important;
  }
}

.mgt70 {
  margin-top: 70px;
}

@media screen and (max-width: 768px) {
  .mgb_sp70 {
    margin-top: 70px !important;
  }
}

.mgt75 {
  margin-top: 75px;
}

@media screen and (max-width: 768px) {
  .mgb_sp75 {
    margin-top: 75px !important;
  }
}

.mgt80 {
  margin-top: 80px;
}

@media screen and (max-width: 768px) {
  .mgb_sp80 {
    margin-top: 80px !important;
  }
}

.mgt85 {
  margin-top: 85px;
}

@media screen and (max-width: 768px) {
  .mgb_sp85 {
    margin-top: 85px !important;
  }
}

.mgt90 {
  margin-top: 90px;
}

@media screen and (max-width: 768px) {
  .mgb_sp90 {
    margin-top: 90px !important;
  }
}

.mgt95 {
  margin-top: 95px;
}

@media screen and (max-width: 768px) {
  .mgb_sp95 {
    margin-top: 95px !important;
  }
}

.mgt100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mgb_sp100 {
    margin-top: 100px !important;
  }
}

/* ==========================================================================
   Other
  ========================================================================== */
/* ==========================================================================
   Fonts
  ========================================================================== */
@font-face {
  font-family: 'SchnyderM';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("/en/assets/font/SchnyderM-Bold-Web.eot");
  src: url("/en/assets/font/SchnyderM-Bold-Web.eot?#iefix") format("embedded-opentype"), url("/en/assets/font/SchnyderM-Bold-Web.woff2") format("woff2"), url("/en/assets/font/SchnyderM-Bold-Web.woff") format("woff");
}

@font-face {
  font-family: 'CentraNo2';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
  src: url("/en/assets/font/CentraNo2-Book.eot");
  src: url("/en/assets/font/CentraNo2-Book.eot?#iefix") format("embedded-opentype"), url("/en/assets/font/CentraNo2-Book.woff2") format("woff2"), url("/en/assets/font/CentraNo2-Book.woff") format("woff");
}

/*----- 3.0 Header & Footer -----*/
/* ==========================================================================
   Header
  ========================================================================== */
header.global {
  position: relative;
  width: 100%;
  z-index: 2;
  opacity: 1;
  pointer-events: auto;
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

header.global.disabled {
  opacity: 0;
  pointer-events: none;
}

header.global a:hover svg path {
  fill: #ffc300;
}

header.global .logo,
header.global .booking,
header.global .nav,
header.global .tglbtn {
  position: fixed;
  z-index: 2;
}

@media screen and (min-width: 769px) {
  header.global .logo {
    top: 80px;
    left: 80px;
  }
}

@media screen and (max-width: 768px) {
  header.global .logo {
    width: 42px;
    top: 25px;
    left: 25px;
  }
  header.global .logo svg {
    width: 100%;
    height: auto;
  }
}

header.global .booking {
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  header.global .booking {
    top: 80px;
    padding-bottom: 9px;
  }
}

@media screen and (max-width: 768px) {
  header.global .booking {
    top: 25px;
    padding-bottom: 6px;
  }
}

header.global .booking::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #000;
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

header.global .booking:hover::after {
  background: #ffc300;
}

header.global .tglbtn {
  position: fixed;
  top: 15px;
  right: 10px;
  width: 40px;
  height: 36px;
  overflow: hidden;
}

header.global .tglbtn::before, header.global .tglbtn::after {
  content: '';
  display: block;
  position: absolute;
  top: 10px;
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

header.global .tglbtn::before {
  width: 20px;
  height: 16px;
  background: url(/assets/img/common/nav_open.png) no-repeat center center;
  background-size: 20px auto;
  left: 10px;
  transform: translateY(0);
}

header.global .tglbtn::after {
  width: 16px;
  height: 16px;
  background: url(/assets/img/common/nav_close.png) no-repeat center center;
  background-size: 16px auto;
  left: 13px;
  transform: translateY(200%);
}

header.global .tglbtn.current::before {
  transform: translateY(-200%);
}

header.global .tglbtn.current::after {
  transform: translateY(0);
}

header.global .nav {
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

@media screen and (min-width: 769px) {
  header.global .nav {
    top: 50%;
    right: 130px;
    transform: translate(50%, -50%);
    width: 148px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  header.global .nav {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
  }
  header.global .nav_in {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  header.global .nav.current {
    pointer-events: auto;
    opacity: 1;
  }
}

header.global .nav .global {
  padding-bottom: 15px;
}

header.global .nav .global a {
  display: block;
  text-align: center;
}

@media screen and (min-width: 769px) {
  header.global .nav .global a {
    margin: 10px 0;
    padding: 5px 0;
  }
}

@media screen and (max-width: 768px) {
  header.global .nav .global a {
    margin: 10px 0;
    padding: 8px 0;
  }
}

header.global .nav .global a span {
  display: inline-block;
  position: relative;
}

header.global .nav .global a span::after {
  content: '';
  position: absolute;
  display: inline-block;
  border-radius: 50%;
  background: #ffc300;
  width: 0px;
  height: 0px;
  left: -22px;
  top: 0;
}

header.global .nav .global a.current span {
  display: inline-block;
  position: relative;
}

header.global .nav .global a.current span::after {
  width: 15px;
  height: 15px;
}

header.global .nav .sns {
  margin-bottom: 30px;
  text-align: center;
}

@media screen and (min-width: 769px) {
  header.global .nav .sns a {
    margin: 0 5px;
  }
}

@media screen and (max-width: 768px) {
  header.global .nav .sns a {
    margin: 0 10px;
  }
}

@media screen and (min-width: 769px) {
  header.global .nav .sns a svg {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 768px) {
  header.global .nav .sns a svg {
    width: 30px;
    height: 30px;
  }
}

header.global .nav .lang {
  position: relative;
  text-align: center;
}

header.global .nav .lang::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  background: #000;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 769px) {
  header.global .nav .lang::after {
    height: 11px;
  }
}

@media screen and (max-width: 768px) {
  header.global .nav .lang::after {
    height: 14px;
    margin-left: -1px;
  }
}

header.global .nav .lang a {
  margin: 0 4px;
  padding: 5px;
}

header.global .nav .lang a svg {
  width: auto;
}

@media screen and (min-width: 769px) {
  header.global .nav .lang a svg {
    width: 20px;
    height: 11px;
  }
}

@media screen and (max-width: 768px) {
  header.global .nav .lang a svg {
    height: 14px;
  }
}

header.global .nav .lang a.current svg path {
  fill: #ffc300;
}

/* ==========================================================================
   Footer
  ========================================================================== */
footer.global .in {
  width: 100%;
}

@media screen and (min-width: 769px) {
  footer.global .in {
    display: flex;
    max-width: 1020px;
  }
}

footer.global .in:last-child {
  display: block;
}

@media screen and (min-width: 769px) {
  footer.global .in:last-child {
    padding-top: 100px;
  }
}

footer.global .in a:hover svg path {
  fill: #ffc300;
}

@media screen and (min-width: 769px) {
  footer.global .in .logo {
    width: 98px;
  }
  footer.global .in .logo img {
    display: inline-block;
    width: 98px;
  }
}

@media screen and (min-width: 769px) {
  footer.global .in .nav {
    flex: 1;
    display: flex;
    padding-left: 240px;
    padding-right: 40px;
  }
  footer.global .in .nav > * {
    width: calc(100% / 3);
  }
}

footer.global .in .nav .global a {
  display: block;
  margin-bottom: 22px;
}

footer.global .in .nav .global a svg {
  height: 12px;
  width: auto;
}

@media screen and (max-width: 768px) {
  footer.global .in .nav .sns {
    margin-bottom: 50px;
    text-align: center;
  }
}

footer.global .in .nav .sns .label {
  display: block;
  margin-bottom: 20px;
}

footer.global .in .nav .sns .label.label_faq {
  margin-top: 26px;
  margin-bottom: 0;
}

@media screen and (min-width: 769px) {
  footer.global .in .nav .sns a {
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  footer.global .in .nav .sns a {
    margin: 0 10px;
  }
}

@media screen and (min-width: 769px) {
  footer.global .in .nav .sns a svg {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 768px) {
  footer.global .in .nav .sns a svg {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 768px) {
  footer.global .in .nav .other {
    margin-bottom: 40px;
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  footer.global .in .nav .other a {
    display: block;
    margin-bottom: 22px;
  }
}

@media screen and (max-width: 768px) {
  footer.global .in .nav .other a {
    margin: 0 8px;
  }
  footer.global .in .nav .other a svg {
    height: 9.5px;
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  footer.global .in .address {
    margin-bottom: 32px;
    text-align: center;
  }
}

footer.global .in .address dt {
  font-weight: 700;
}

@media screen and (min-width: 769px) {
  footer.global .in .address dt {
    padding-bottom: 6px;
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  footer.global .in .address dt {
    padding-bottom: 15px;
    font-size: 14px;
  }
}

footer.global .in .address dd {
  font-size: 12px;
  line-height: 1.7;
}

footer.global .in .totop {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

@media screen and (min-width: 769px) {
  footer.global .in .copyright {
    position: absolute;
    display: inline-block;
    right: 0;
    bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  footer.global .in .copyright {
    text-align: center;
  }
}

#fmap {
  display: block;
  width: 100%;
  background: #dedede;
}

@media screen and (min-width: 769px) {
  #fmap {
    margin-top: 70px;
    height: 540px;
  }
}

@media screen and (max-width: 768px) {
  #fmap {
    margin-top: 37px;
    height: 300px;
  }
}

/* ==========================================================================
   frame
  ========================================================================== */
html,
body {
  height: 100%;
  width: 100%;
}

body {
  color: #000;
  font-family: "Raleway", sans-serif;
  background-color: #fff;
  overflow-x: hidden;
  font-size: 14px;
  font-weight: 600;
}

body.fixed {
  overflow: hidden;
}

body.jp,
body .jp {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', sans-serif;
}

body .en {
  font-family: 'Raleway', sans-serif;
}

@media screen and (min-width: 769px) {
  .js-scroll-contents {
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
  }
  .js-scroll-dummy {
    position: relative;
    width: 100%;
    opacity: 0;
    z-index: -1;
    height: 100vh;
  }
}

#wrap {
  width: 100%;
  opacity: 0;
  overflow: hidden;
  transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

#wrap.ready {
  opacity: 1;
}

#wrap.fadeout {
  opacity: 0;
}

.in {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

@media screen and (min-width: 769px) {
  .in {
    min-width: 940px;
    max-width: 980px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (min-width: 769px) {
  .in_480 {
    width: 520px;
  }
}

@media screen and (min-width: 769px) {
  .in_800 {
    max-width: 840px;
  }
}

@media screen and (min-width: 769px) {
  .in_1028 {
    max-width: 1068px;
  }
}

@media screen and (max-width: 768px) {
  .in_1028 {
    padding-left: 25px;
    padding-right: 25px;
  }
}

.in_full {
  padding-left: 0;
  padding-right: 0;
}

/*----- 4.0 Components -----*/
/* ==========================================================================
   Parts
  ========================================================================== */
svg path {
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.hide {
  display: none !important;
}

.vh100 {
  height: 100vh;
}

.js-in {
  transition-duration: 1.2s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-property: opacity, transform;
  opacity: 0;
}

@media screen and (min-width: 769px) {
  .js-in {
    transform: translateY(30px);
  }
}

@media screen and (max-width: 768px) {
  .js-in {
    transform: translateY(20px);
  }
}

.js-in.inview {
  opacity: 1;
  transform: translateY(0);
}

/* link */
a {
  display: inline-block;
  outline: none;
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  color: #000;
}

a:focus {
  outline: none;
}

/* btn */
.btn {
  position: relative;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: 2px solid #000;
  font-weight: 700;
}

@media screen and (min-width: 769px) {
  .btn {
    padding: 22px;
    border-radius: 36px;
    width: 320px;
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .btn {
    padding: 16px;
    border-radius: 28px;
    width: 100%;
    font-size: 17px;
  }
}

.btn .ico, .btn .lbl {
  margin-right: 5px;
  vertical-align: middle;
}

.btn:hover {
  border: 2px solid #ffc300;
  background: #ffc300;
  color: #fff;
}

.btn:hover svg path {
  fill: #fff;
}

@media screen and (min-width: 769px) {
  .btn.mdl {
    padding: 15px;
    width: 300px;
    border-radius: 24px;
    font-size: 13px;
  }
}

@media screen and (min-width: 769px) {
  .nav_page {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 769px) {
  .nav_page .btn {
    margin: 0 12px;
    width: 180px;
    height: 140px;
  }
}

@media screen and (max-width: 768px) {
  .nav_page .btn {
    padding-top: 25px;
    margin-bottom: 10px;
    width: 100%;
    height: 70px;
    text-align: center;
  }
  .nav_page .btn > * {
    vertical-align: middle;
  }
}

.nav_page .btn::before, .nav_page .btn::after {
  content: '';
  display: block;
  position: absolute;
}

.nav_page .btn::before {
  top: 1px;
  left: 1px;
  width: calc(100% - 2px);
  height: 2px;
}

.nav_page .btn::after {
  right: 1px;
  bottom: 1px;
  border-top: 10px solid transparent;
}

.nav_page .btn .icon {
  display: inline-block;
}

@media screen and (min-width: 769px) {
  .nav_page .btn .icon {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 42px;
    left: 50%;
  }
  .nav_page .btn .icon img {
    height: 24px;
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .nav_page .btn .icon {
    margin-right: 10px;
  }
  .nav_page .btn .icon img {
    height: 22px;
    width: auto;
  }
}

.nav_page .btn .label {
  font-weight: 500;
}

@media screen and (min-width: 769px) {
  .nav_page .btn .label {
    position: relative;
    width: 100%;
    display: block;
    top: 88px;
    text-align: center;
    transform: translateY(-50%);
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  .nav_page .btn .label {
    text-align: left;
    display: inline-block;
  }
}

@media screen and (min-width: 769px) {
  .nav_page .btn .label .min {
    font-size: 80%;
  }
}

@media screen and (max-width: 768px) {
  .nav_page .btn_c04 {
    padding-top: 15px;
  }
}

@media screen and (min-width: 769px) {
  .nav_page .btn_c04 .label {
    top: 92px;
  }
}

@media screen and (max-width: 768px) {
  .nav_page .btn_c04 .label {
    line-height: 1.3;
    font-weight: 400;
  }
}

.nav_page .btn.disabled {
  cursor: default;
}

.nav_page .btn.disabled .label {
  color: #bababa;
}

.nav_page .btn.disabled::before {
  background: #bababa;
}

.nav_page .btn.disabled::after {
  border-right: 10px solid #bababa;
}

.nav_back {
  text-align: center;
}

.nav_back .btn > * {
  vertical-align: text-bottom;
}

@media screen and (min-width: 769px) {
  .nav_back .btn .icon {
    margin-right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .nav_back .btn .icon {
    margin-right: 10px;
  }
}

.nav_back .btn .icon img {
  width: 30px;
  vertical-align: middle;
}

@media screen and (min-width: 769px) {
  .nav_back .btn {
    margin: 0 auto;
    padding: 26px 10px;
    width: 380px;
    height: 70px;
  }
}

@media screen and (max-width: 768px) {
  .nav_back .btn {
    margin: 0 auto;
    padding: 18px 10px;
    width: 100%;
    height: 55px;
  }
}

/* loading */
/* ==========================================================================
   vendor
  ========================================================================== */
/* lazyload */
.limg {
  opacity: 0;
  transition: 1s cubic-bezier(0.23, 1, 0.32, 1);
}

.lazyloaded {
  opacity: 1;
}

/* swiper */
.swiper-container .swiper-button-prev,
.swiper-container .swiper-button-next {
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .swiper-container .swiper-button-prev,
  .swiper-container .swiper-button-next {
    display: none;
  }
}

.swiper-container .swiper-button-prev::before, .swiper-container .swiper-button-prev::after,
.swiper-container .swiper-button-next::before,
.swiper-container .swiper-button-next::after {
  position: absolute;
  content: '';
  display: inline-block;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  transform: translate(-50%, -50%);
  transition: 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.swiper-container .swiper-button-prev::before,
.swiper-container .swiper-button-next::before {
  opacity: 1;
}

.swiper-container .swiper-button-prev::after,
.swiper-container .swiper-button-next::after {
  opacity: 0;
}

.swiper-container .swiper-button-prev:hover::before,
.swiper-container .swiper-button-next:hover::before {
  opacity: 0;
}

.swiper-container .swiper-button-prev:hover::after,
.swiper-container .swiper-button-next:hover::after {
  margin-left: 0;
  opacity: 1;
}

.swiper-container .swiper-button-prev {
  left: 15px;
}

.swiper-container .swiper-button-prev::before {
  background: url(/en/assets/img/common/ico_arr2.svg) no-repeat center center;
  background-size: cover;
}

.swiper-container .swiper-button-prev::after {
  margin-left: 3em;
  background: url(/en/assets/img/common/ico_arr2_ov.svg) no-repeat center center;
  background-size: cover;
}

.swiper-container .swiper-button-prev:hover::before {
  margin-left: -3em;
}

.swiper-container .swiper-button-prev:hover::after {
  margin-left: 0;
}

.swiper-container .swiper-button-next {
  right: 15px;
}

.swiper-container .swiper-button-next::before {
  background: url(/en/assets/img/common/ico_arr.svg) no-repeat center center;
  background-size: cover;
}

.swiper-container .swiper-button-next::after {
  margin-left: -3em;
  background: url(/en/assets/img/common/ico_arr_ov.svg) no-repeat center center;
  background-size: cover;
}

.swiper-container .swiper-button-next:hover::before {
  margin-left: 3em;
}

.swiper-container .swiper-button-next:hover::after {
  margin-left: 0em;
}

.swiper-container .swiper-pagination {
  position: relative;
  bottom: auto;
}

@media screen and (min-width: 769px) {
  .swiper-container .swiper-pagination {
    margin: 18px 0 0;
    text-align: right;
  }
}

@media screen and (max-width: 768px) {
  .swiper-container .swiper-pagination {
    margin: 13px 0 0;
  }
}

.swiper-container .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #e6e6e6;
  opacity: 1;
}

@media screen and (min-width: 769px) {
  .swiper-container .swiper-pagination .swiper-pagination-bullet {
    margin: 0 0 0 5px;
  }
}

@media screen and (max-width: 768px) {
  .swiper-container .swiper-pagination .swiper-pagination-bullet {
    margin: 0 3px;
  }
}

.swiper-container .swiper-pagination .swiper-pagination-bullet-active {
  background: #ffc300;
}

/* ==========================================================================
   Animation
  ========================================================================== */
/* loading */
@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes arrFloat {
  0% {
    transform: translate(-50%, -10px);
  }
  70% {
    transform: translate(-50%, 0px);
  }
  100% {
    transform: translate(-50%, -10px);
  }
}

@keyframes bgGrad {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/*----- 5.0 Pages Components -----*/
/* ==========================================================================
   pages: Index
   ========================================================================== */
body.index .kv {
  position: relative;
}

@media screen and (min-width: 769px) {
  body.index .kv .catch {
    margin-top: 240px;
    margin-bottom: 125px;
  }
}

@media screen and (max-width: 768px) {
  body.index .kv .catch {
    margin-top: 120px;
    margin-bottom: 38px;
  }
}

body.index .kv .catch span {
  text-align: center;
  display: block;
  overflow: hidden;
}

@media screen and (min-width: 769px) {
  body.index .kv .catch span {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 768px) {
  body.index .kv .catch span {
    margin-bottom: 6px;
  }
}

body.index .kv .catch span:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  body.index .kv .catch span.catch_1 svg {
    width: auto;
    height: 22px;
  }
}

body.index .kv .catch span.catch_2 svg {
  transition-delay: .1s;
}

@media screen and (max-width: 768px) {
  body.index .kv .catch span.catch_2 svg {
    width: auto;
    height: 19px;
  }
}

body.index .kv .catch span svg {
  transition: 1.2s cubic-bezier(0.23, 1, 0.32, 1);
  transform: translateY(110%);
}

body.index .kv .catch.active span svg {
  transform: translateY(0);
}

body.index .kv .scroll {
  position: absolute;
  right: 110px;
  bottom: -48vh;
  z-index: 10;
  animation: arrFloat 1.5s infinite;
}

body.index .imgs {
  position: relative;
  width: 100%;
}

body.index .imgs .img {
  position: relative;
}

body.index .imgs .img img {
  display: block;
  width: 100%;
  height: auto;
}

body.index .imgs .txt {
  position: relative;
  line-height: 1.5;
}

@media screen and (max-width: 768px) {
  body.index .imgs .txt {
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.6;
  }
}

@media screen and (min-width: 769px) {
  body.index .imgs .img01 {
    width: 64.1vw;
  }
}

body.index .imgs .txt01 {
  transition-delay: .2s;
}

@media screen and (min-width: 769px) {
  body.index .imgs .txt01 {
    position: absolute;
    margin-top: -34.5vw;
    left: 60.4vw;
    font-size: 15px;
    font-weight: 700;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .txt01 {
    margin: 30px 0 50px;
  }
}

body.index .imgs .txt01.txt-jp {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.7;
}

@media screen and (min-width: 769px) {
  body.index .imgs .img02 {
    width: 24.5vw;
    margin-top: -5vw;
    left: 69.1vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .img02 {
    width: 26.7vw;
    left: 58.7vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .imgs .txt02 {
    position: absolute;
    margin-top: -7.3em;
    left: 14.1vw;
    font-size: 26px;
    font-weight: 700;
  }
}

body.index .imgs .txt02.txt-jp {
  font-size: 30px;
  line-height: 1.6;
}

@media screen and (min-width: 769px) {
  body.index .imgs .img03 {
    width: 45.8vw;
    margin-top: 13.3vw;
    left: 42.9vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .img03 {
    width: 44.0vw;
    left: 20px;
    margin-top: -5.3vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .imgs .img04 {
    width: 27.5vw;
    margin-top: 3.0vw;
    left: 9vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .img04 {
    width: 32.0vw;
    left: calc(68vw - 20px);
    margin-top: -13vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .imgs .img05 {
    width: 50.8vw;
    margin-top: -13.7vw;
    left: 43.0vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .img05 {
    left: 20px;
    width: calc(100% - 40px);
    margin-top: 13.3vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .imgs .img06 {
    width: 23.3vw;
    margin-top: 9.1vw;
    left: 5.6vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .img06 {
    width: 29.3vw;
    left: 9.3vw;
    margin-top: 13.3vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .imgs .img07 {
    width: 25.4vw;
    margin-top: -8.3vw;
    left: 34.3vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .img07 {
    width: 33.3vw;
    left: 58.7vw;
    margin-top: -18.6vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .imgs .img08 {
    width: 28.8vw;
    margin-top: -39.6vw;
    left: 65.0vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .img08 {
    width: 37.3vw;
    left: 13.3vw;
    margin-top: -9.6vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .imgs .img09 {
    width: 43.3vw;
    margin-top: 31.0vw;
    left: 5.5vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .img09 {
    left: 20px;
    width: calc(100% - 40px);
    margin-top: 13.3vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .imgs .txt03 {
    position: absolute;
    margin-top: -23.4vw;
    left: 54.5vw;
    font-size: 26px;
    font-weight: 700;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .txt03 {
    margin: 30px 0 0px;
  }
}

body.index .imgs .txt03.txt-jp {
  line-height: 1.6;
}

@media screen and (min-width: 769px) {
  body.index .imgs .txt03.txt-jp {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  body.index .imgs .txt03.txt-jp {
    font-size: 16px;
  }
}

@media screen and (min-width: 769px) {
  body.index .intro {
    margin-top: 150px;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro {
    margin-top: 50px;
  }
}

body.index .intro .head {
  position: relative;
}

body.index .intro .head::before {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 53.7vw;
  background: #ffc300;
}

body.index .intro .head figure {
  position: relative;
}

@media screen and (min-width: 769px) {
  body.index .intro .head figure {
    padding-top: 80px;
    left: 70px;
    width: calc(100% - 140px);
  }
}

@media screen and (max-width: 768px) {
  body.index .intro .head figure {
    padding-top: 25px;
    left: 20px;
    width: calc(100% - 40px);
  }
}

body.index .intro .head figure img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 769px) {
  body.index .intro .head h2.title {
    margin: 70px auto 0;
    width: calc(100% - 260px);
  }
}

@media screen and (max-width: 768px) {
  body.index .intro .head h2.title {
    margin: 32px auto 0;
    width: calc(100% - 64px);
  }
}

body.index .intro .head h2.title .num {
  display: inline-block;
  background: url(/assets/img/top/ico_wave.svg) no-repeat center bottom;
}

@media screen and (min-width: 769px) {
  body.index .intro .head h2.title .num {
    padding: 0 5px 18px;
    background-size: auto 12px;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro .head h2.title .num {
    width: 32px;
    padding: 0 2px 8px;
    background-size: auto 6px;
  }
  body.index .intro .head h2.title .num svg {
    width: 26px;
    height: auto;
  }
}

body.index .intro .head h2.title .lbl {
  display: block;
  text-align: center;
}

@media screen and (min-width: 769px) {
  body.index .intro .head h2.title .lbl {
    margin-top: 95px;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro .head h2.title .lbl {
    margin-top: 22px;
  }
}

body.index .intro .main {
  position: relative;
}

body.index .intro .main p.lead,
body.index .intro .main p.info {
  position: relative;
}

@media screen and (min-width: 769px) {
  body.index .intro .main p.lead,
  body.index .intro .main p.info {
    font-size: 26px;
    font-weight: 700;
    line-height: 1.5;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro .main p.lead,
  body.index .intro .main p.info {
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.6;
  }
}

body.index .intro .main p.lead.txt-jp,
body.index .intro .main p.info.txt-jp {
  line-height: 1.5;
}

@media screen and (min-width: 769px) {
  body.index .intro .main p.lead.txt-jp,
  body.index .intro .main p.info.txt-jp {
    font-size: 28px;
  }
}

body.index .intro .main figure.img {
  position: relative;
}

body.index .intro .main figure.img img {
  width: 100%;
  height: auto;
}

@media screen and (min-width: 769px) {
  body.index .intro#intro01 h2.title {
    margin-bottom: 195px;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro#intro01 h2.title {
    margin-bottom: 40px;
  }
  body.index .intro#intro01 h2.title .lbl img {
    height: 30px;
    width: auto;
  }
}

@media screen and (min-width: 769px) {
  body.index .intro#intro01 .main .lead {
    position: absolute;
    left: 14.4vw;
  }
  body.index .intro#intro01 .main .img10 {
    top: 0;
    left: 65.6vw;
    width: 28.3vw;
  }
  body.index .intro#intro01 .main .img11 {
    margin-top: -12.5vw;
    left: 5.8vw;
    width: 52.5vw;
  }
  body.index .intro#intro01 .main .img12 {
    margin-top: 12.5vw;
    left: 48.0vw;
    width: 45.8vw;
  }
  body.index .intro#intro01 .main .img13 {
    margin-top: -20.8vw;
    left: 10.6vw;
    width: 30.0vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro#intro01 .main .lead {
    margin-bottom: 55px;
  }
  body.index .intro#intro01 .main .img10 {
    top: 0;
    left: 66.7vw;
    width: 28.0vw;
  }
  body.index .intro#intro01 .main .img11 {
    margin-top: -13.3vw;
    left: 20px;
    width: 53.3vw;
  }
  body.index .intro#intro01 .main .img12 {
    margin-top: 9.3vw;
    left: 49.3vw;
    width: 45.3vw;
  }
  body.index .intro#intro01 .main .img13 {
    margin-top: -22.6vw;
    left: 9.3vw;
    width: 30.6vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .intro#intro02 h2.title {
    margin-bottom: 185px;
  }
  body.index .intro#intro02 h2.title .lbl {
    margin-top: 70px;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro#intro02 h2.title {
    margin-bottom: 45px;
  }
  body.index .intro#intro02 h2.title .lbl {
    margin-top: -15px;
  }
  body.index .intro#intro02 h2.title .lbl img {
    height: 120px;
    width: auto;
  }
}

@media screen and (min-width: 769px) {
  body.index .intro#intro02 .main .lead {
    position: absolute;
    left: 48.5vw;
  }
  body.index .intro#intro02 .main .img14 {
    top: 0;
    left: 5.8vw;
    width: 28.3vw;
  }
  body.index .intro#intro02 .main .img15 {
    margin-top: -15.0vw;
    left: 41.5vw;
    width: 52.5vw;
  }
  body.index .intro#intro02 .main .img16 {
    margin-top: 12.3vw;
    left: 5.8vw;
    width: 45.8vw;
  }
  body.index .intro#intro02 .main .img17 {
    margin-top: -20.8vw;
    left: 59.0vw;
    width: 30.0vw;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro#intro02 .main .lead {
    margin-bottom: 55px;
  }
  body.index .intro#intro02 .main .img14 {
    top: 0;
    left: 66.7vw;
    width: 28.0vw;
  }
  body.index .intro#intro02 .main .img15 {
    margin-top: -13.3vw;
    left: 20px;
    width: 53.3vw;
  }
  body.index .intro#intro02 .main .img16 {
    margin-top: 9.3vw;
    left: 49.3vw;
    width: 45.3vw;
  }
  body.index .intro#intro02 .main .img17 {
    margin-top: -22.6vw;
    left: 9.3vw;
    width: 30.6vw;
  }
}

@media screen and (min-width: 769px) {
  body.index .intro#intro03 {
    margin-bottom: 250px;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro#intro03 {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 769px) {
  body.index .intro#intro03 h2.title {
    margin-bottom: 135px;
  }
  body.index .intro#intro03 h2.title span.lbl {
    margin-top: 25px;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro#intro03 h2.title {
    margin-bottom: 30px;
  }
  body.index .intro#intro03 h2.title .lbl {
    margin-top: -10px;
  }
  body.index .intro#intro03 h2.title .lbl img {
    height: 120px;
    width: auto;
  }
}

@media screen and (min-width: 769px) {
  body.index .intro#intro03 .main .lead {
    position: absolute;
    top: 1vw;
    left: 13.1vw;
  }
  body.index .intro#intro03 .main .img18 {
    top: 0;
    left: 59.0vw;
    width: 26.2vw;
  }
  body.index .intro#intro03 .main .img19 {
    margin-top: -1.1vw;
    left: 5.8vw;
    width: 35.4vw;
  }
  body.index .intro#intro03 .main .img20 {
    margin-top: -14.5vw;
    left: 49.0vw;
    width: 45.0vw;
  }
  body.index .intro#intro03 .main .info {
    margin: 9.5vw auto 0;
    width: 980px;
  }
}

@media screen and (max-width: 768px) {
  body.index .intro#intro03 .main .lead {
    margin-bottom: 32px;
  }
  body.index .intro#intro03 .main .img18 {
    top: 0;
    left: 61.3vw;
    width: 25.3vw;
  }
  body.index .intro#intro03 .main .img19 {
    margin-top: -13.3vw;
    left: 20px;
    width: 40.0vw;
  }
  body.index .intro#intro03 .main .img20 {
    margin-top: -5.3vw;
    left: 53.3vw;
    width: 41.3vw;
  }
  body.index .intro#intro03 .main .info {
    margin: 50px 0 0;
  }
}

#dummyCener {
  position: fixed;
  display: block;
  top: 0px;
  left: 50%;
  width: 1px;
  height: 100vh;
  background: #f00;
  margin-left: 1px;
  z-index: 999;
}

/* ==========================================================================
   pages: Page
   ========================================================================== */
body.page .head {
  text-align: center;
}

@media screen and (min-width: 769px) {
  body.page .head {
    padding-top: 270px;
  }
}

@media screen and (max-width: 768px) {
  body.page .head {
    padding-top: 136px;
  }
}

@media screen and (min-width: 769px) {
  body.page .head .title {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  body.page .head .title {
    margin-bottom: 24px;
  }
  body.page .head .title svg {
    height: 27px;
    width: auto;
  }
}

body.page .head .lead {
  font-weight: 700;
  line-height: 1.5;
  transition-delay: .2s;
}

@media screen and (min-width: 769px) {
  body.page .head .lead {
    margin-bottom: 20px;
    font-size: 22px;
  }
}

@media screen and (max-width: 768px) {
  body.page .head .lead {
    margin-bottom: 15px;
    font-size: 17px;
  }
}

body.page .head .txt {
  line-height: 1.7;
  transition-delay: .4s;
}

@media screen and (min-width: 769px) {
  body.page .head .txt {
    margin-bottom: 50px;
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  body.page .head .txt {
    margin-bottom: 26px;
  }
}

body.page .head .txt.txt-jp {
  line-height: 1.8;
}

@media screen and (min-width: 769px) {
  body.page .head .txt.txt-jp {
    font-size: 15px;
  }
}

body.page .head .ico {
  transition-delay: .6s;
}

@media screen and (min-width: 769px) {
  body.page .head .ico {
    margin-bottom: 74px;
  }
}

@media screen and (max-width: 768px) {
  body.page .head .ico {
    margin-bottom: 45px;
  }
  body.page .head .ico svg {
    height: 49px;
    width: auto;
  }
}

body.page .head .img {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 67% 0 0;
}

@media screen and (min-width: 769px) {
  body.page .head .img {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 768px) {
  body.page .head .img {
    margin-bottom: 55px;
  }
}

body.page .head .img_inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body.page .head .img_stay {
  background-image: url(/assets/img/stay/img_stay.jpg);
}

body.page .head .img_eat-drink {
  background-image: url(/assets/img/eat-drink/img_eat-drink.jpg);
}

body.page .head .img_facilities {
  background-image: url(/assets/img/facilities/img_facilities.png);
}

@media screen and (min-width: 769px) {
  body.page .head #amap {
    margin: 70px 0 100px;
    height: 60vw;
  }
}

@media screen and (max-width: 768px) {
  body.page .head #amap {
    margin: 32px 0 54px;
    height: 80vw;
  }
}

body.page h2.title {
  text-align: center;
  font-weight: 800;
}

@media screen and (min-width: 769px) {
  body.page h2.title {
    margin-bottom: 70px;
    font-size: 48px;
  }
}

@media screen and (max-width: 768px) {
  body.page h2.title {
    margin-bottom: 20px;
    font-size: 33px;
  }
}

@media screen and (min-width: 769px) {
  body.page h3.title {
    font-weight: 700;
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  body.page h3.title {
    font-weight: 800;
    font-size: 22px;
  }
}

body.page h3.title.txt-jp {
  line-height: 1.3;
}

@media screen and (min-width: 769px) {
  body.page h3.title.txt-jp {
    font-size: 33px;
  }
}

@media screen and (max-width: 768px) {
  body.page h3.title.txt-jp {
    font-size: 23px;
  }
}

body.page h4.title {
  font-weight: 800;
}

@media screen and (min-width: 769px) {
  body.page h4.title {
    margin-bottom: 15px;
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  body.page h4.title {
    margin-bottom: 20px;
    font-size: 33px;
  }
}

@media screen and (max-width: 768px) {
  body.page .js-sp_swipe {
    padding: 0 20px;
  }
}

body.page .types {
  position: relative;
  display: flex;
}

@media screen and (min-width: 769px) {
  body.page .types {
    margin-bottom: 95px;
  }
}

@media screen and (max-width: 768px) {
  body.page .types {
    margin: 20px 0 45px;
    width: 100%;
  }
}

body.page .types::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #e0e0e0;
}

body.page .types_nav {
  position: relative;
  text-align: center;
  font-weight: 800;
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

body.page .types_nav::before, body.page .types_nav::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  opacity: 0;
  background: #ffc300;
  transition: 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

@media screen and (min-width: 769px) {
  body.page .types_nav::before {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  body.page .types_nav::before {
    opacity: 1;
    background: #e0e0e0;
  }
}

body.page .types_nav.active::after {
  height: 2px;
  opacity: 1;
}

body.page .types_nav:hover {
  color: #ffc300;
}

@media screen and (min-width: 769px) {
  body.page .types_nav {
    padding: 22px 10px;
    width: 25%;
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  body.page .types_nav {
    padding: 12px 16px 14px;
    font-size: 12px;
    width: auto;
  }
}

body.page .js-tab-contents {
  overflow: hidden;
  height: 0;
}

body.page .js-tab-contents.active {
  height: auto;
}

@media screen and (min-width: 769px) {
  body.page .block {
    display: flex;
    margin-bottom: 50px;
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 768px) {
  body.page .block {
    margin-bottom: 50px;
    padding: 0 20px;
  }
}

@media screen and (min-width: 769px) {
  body.page .block + .notice {
    margin-top: -10px;
  }
}

@media screen and (max-width: 768px) {
  body.page .block + .notice {
    margin-top: -25px;
  }
}

@media screen and (min-width: 769px) {
  body.page .block_img {
    width: 50.5%;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_img {
    position: relative;
    width: calc(100vw - 20px);
  }
}

@media screen and (max-width: 768px) {
  body.page .block_img.single {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_img .link {
    margin-top: 28px;
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  body.page .block_txt {
    flex: 1;
    padding-left: 52px;
  }
}

@media screen and (min-width: 769px) {
  body.page .block_txt h2.title {
    margin-bottom: 24px;
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_txt h2.title {
    margin-bottom: 20px;
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  body.page .block_txt h3.title {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_txt h3.title {
    margin-bottom: 20px;
    text-align: center;
  }
}

body.page .block_txt h3.catch {
  line-height: 1.5;
}

@media screen and (min-width: 769px) {
  body.page .block_txt h3.catch {
    margin-bottom: 15px;
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_txt h3.catch {
    margin-bottom: 17px;
    font-size: 17px;
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  body.page .block_txt .txt {
    margin-bottom: 26px;
    line-height: 1.8;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_txt .txt {
    margin-bottom: 26px;
    line-height: 1.5;
  }
}

@media screen and (min-width: 769px) {
  body.page .block_txt .txt:last-child {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_txt .txt.txt-jp {
    font-size: 13px;
    line-height: 1.8;
  }
}

@media screen and (min-width: 769px) {
  body.page .block_access {
    margin-bottom: 180px;
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_access {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 769px) {
  body.page .block_access .block_img {
    width: 50%;
    padding-left: 112px;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_access .block_img {
    padding-bottom: 25px;
    text-align: center;
    width: calc(100vw - 40px);
  }
}

@media screen and (min-width: 769px) {
  body.page .block_access .block_txt {
    margin-top: -8px;
    padding-left: 30px;
  }
}

@media screen and (min-width: 769px) {
  body.page .block_access .block_txt h3.catch {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_access .block_txt h3.catch {
    margin: 25px 0 10px;
  }
}

@media screen and (min-width: 769px) {
  body.page h2.sub-title {
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  body.page h2.sub-title {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 769px) {
  body.page .sub-blocks {
    display: flex;
  }
  body.page .sub-blocks .block {
    flex-direction: column-reverse;
    margin-right: 50px;
    margin-bottom: 0;
    width: calc(50% - 25px);
  }
  body.page .sub-blocks .block:nth-child(even) {
    margin-right: 0;
  }
  body.page .sub-blocks .block_img {
    margin-bottom: 28px;
    width: 100%;
  }
  body.page .sub-blocks .block_txt {
    padding-left: 0;
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  body.page .sub-blocks .title {
    margin-bottom: 15px;
    font-size: 22px;
    text-align: center;
  }
}

body.page .notice {
  background: #f5f5f5;
}

@media screen and (min-width: 769px) {
  body.page .notice {
    margin-bottom: 25px;
    padding: 42px 48px 40px;
  }
}

@media screen and (max-width: 768px) {
  body.page .notice {
    margin: 0 auto 16px;
    padding: 26px 20px 24px;
    width: calc(100% - 40px);
  }
}

body.page .notice dl:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 769px) {
  body.page .notice dl {
    margin-bottom: 28px;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  body.page .notice dl {
    margin-bottom: 22px;
  }
}

body.page .notice dl dt {
  font-weight: 700;
}

@media screen and (min-width: 769px) {
  body.page .notice dl dt {
    width: 160px;
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  body.page .notice dl dt {
    margin-bottom: 10px;
    font-size: 17px;
  }
}

body.page .notice dl dd {
  line-height: 1.5;
}

@media screen and (min-width: 769px) {
  body.page .notice dl dd {
    flex: 1;
  }
}

@media screen and (min-width: 769px) {
  body.page .notice dl dd.txt-jp {
    font-size: 19px;
  }
}

@media screen and (max-width: 768px) {
  body.page .notice dl dd.txt-jp {
    font-size: 13px;
    line-height: 1.6;
  }
}

body.page .tripadvisor {
  text-align: right;
}

@media screen and (min-width: 769px) {
  body.page .tripadvisor {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 768px) {
  body.page .tripadvisor {
    margin-bottom: 55px;
    padding-right: 20px;
  }
}

body.page .tripadvisor a {
  background: url(/assets/img/common/ico_tripadvisor.svg) no-repeat left center;
}

@media screen and (min-width: 769px) {
  body.page .tripadvisor a {
    padding: 6px 0px 5px 45px;
    background-size: 34px auto;
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  body.page .tripadvisor a {
    padding: 6px 0px 4px 38px;
    background-size: 26px auto;
    font-size: 15px;
  }
}

body.page .tripadvisor a:hover {
  color: #ffc300;
}

body.page .aside.links {
  text-align: center;
}

@media screen and (min-width: 769px) {
  body.page .aside.links {
    margin-top: 130px;
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 768px) {
  body.page .aside.links {
    margin-top: 90px;
    padding-bottom: 75px;
  }
}

@media screen and (min-width: 769px) {
  body.page .aside.links .link {
    display: inline-block;
    margin: 0 12px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  body.page .aside.links .link {
    margin-bottom: 30px;
    padding: 0 20px;
    font-size: 11px;
  }
}

@media screen and (min-width: 769px) {
  body.page .aside.links .link .btn {
    margin-bottom: 18px;
  }
}

@media screen and (max-width: 768px) {
  body.page .aside.links .link .btn {
    margin-bottom: 12px;
  }
}

body.page.access #fmap {
  margin: 0;
  opacity: 0;
}

@media screen and (min-width: 769px) {
  body.page.access #fmap {
    height: 70px;
  }
}

@media screen and (max-width: 768px) {
  body.page.access #fmap {
    height: 35px;
  }
}

body.page .block_faq {
  display: block;
}

@media screen and (min-width: 769px) {
  body.page .block_faq {
    margin: 80px auto 0;
    padding-top: 78px;
    border-top: 2px solid #000;
    max-width: 600px;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_faq {
    margin: 40px auto 0;
    padding: 36px 0 0;
    border-top: 1px solid #000;
    width: calc(100% - 36px);
  }
}

@media screen and (min-width: 769px) {
  body.page .block_faq h2.title {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_faq h2.title {
    margin-bottom: 28px;
  }
}

body.page .block_faq .faq_item_q, body.page .block_faq .faq_item_a {
  display: flex;
}

body.page .block_faq .faq_item_q .txt, body.page .block_faq .faq_item_a .txt {
  flex: 1;
}

body.page .block_faq .faq_item_q {
  margin-bottom: 0.8em;
}

@media screen and (min-width: 769px) {
  body.page .block_faq .faq_item_q {
    font-size: 20px;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_faq .faq_item_q {
    font-weight: 700;
  }
}

body.page .block_faq .faq_item_q .txt {
  letter-spacing: 0.03em;
  line-height: 1.5;
  vertical-align: text-bottom;
}

@media screen and (min-width: 769px) {
  body.page .block_faq .faq_item_q .txt.jp {
    font-size: 19px;
  }
}

body.page .block_faq .faq_item_q .ico {
  width: 1.3em;
  line-height: 1.5;
}

@media screen and (min-width: 769px) {
  body.page .block_faq .faq_item_a {
    margin-bottom: 3em;
  }
}

@media screen and (max-width: 768px) {
  body.page .block_faq .faq_item_a {
    margin-bottom: 2em;
  }
}

body.page .block_faq .faq_item_a .txt {
  margin-top: -3px;
  letter-spacing: 0.03em;
  line-height: 1.8;
  font-weight: 500;
}

body.page .block_faq .faq_item_a .txt a {
  text-decoration: underline;
}

body.page .block_faq .faq_item_a .ico {
  color: #ffc300;
  font-weight: 600;
  width: 1.3em;
}

@media screen and (min-width: 769px) {
  body.page .block_faq .faq_item_a .ico {
    font-size: 20px;
  }
}

/*----- 6.0 Popup pages -----*/
