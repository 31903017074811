/* ==========================================================================
   Header
  ========================================================================== */

header.global {
  position: relative;
  width: 100%;
  z-index: 2;
  opacity: 1;
  pointer-events: auto;
  transition: .6s $Base;

  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
  a:hover {
    svg {
      path {
        fill: $c_yellow;
      }
    }
  }

  .logo,
  .booking,
  .nav,
  .tglbtn {
    position: fixed;
    z-index: 2;
  }
  .logo {
    @include mq(pc) {
      top: 80px;
      left: 80px;
    }
    @include mq(sp) {
      width: 42px;
      top: 25px;
      left: 25px;
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
  .booking {
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;

    @include mq(pc) {
      top: 80px;
      padding-bottom: 9px;
    }
    @include mq(sp) {
      top: 25px;
      padding-bottom: 6px;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #000;
      transition: .6s $Base;
    }
    &:hover {
      &::after {
        background: $c_yellow;
      }
    }
  }
  .tglbtn {
    position: fixed;
    top: 15px;
    right: 10px;
    width: 40px;
    height: 36px;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 10px;
      transition: .6s $Base;
    }
    &::before {
      width: 20px;
      height: 16px;
      background: url(/assets/img/common/nav_open.png) no-repeat center center;
      background-size: 20px auto;
      left: 10px;
      transform: translateY(0);
    }
    &::after {
      width: 16px;
      height: 16px;
      background: url(/assets/img/common/nav_close.png) no-repeat center center;
      background-size: 16px auto;
      left: 13px;
      transform: translateY(200%);
    }
    &.current {
      &::before {
        transform: translateY(-200%);
      }
      &::after {
        transform: translateY(0);
      }
    }
  }
  .nav {
    transition: .6s $Base;

    @include mq(pc) {
      top: 50%;
      right: 130px;
      transform: translate(50%,-50%);
      width: 148px;
      text-align: center;
    }
    @include mq(sp) {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: #fff;
      z-index: 1;
      pointer-events: none;
      opacity: 0;

      &_in {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      &.current {
        pointer-events: auto;
        opacity: 1;
      }
    }

    .global {
      padding-bottom: 15px;

      a {
        display: block;
        text-align: center;
        @include mq(pc) {
          margin: 10px 0;
          padding: 5px 0;
        }
        @include mq(sp) {
          margin: 10px 0;
          padding: 8px 0;
        }

        span {
          display: inline-block;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            display: inline-block;
            border-radius: 50%;
            background: $c_yellow;
            width: 0px;
            height: 0px;
            left: -22px;
            top: 0;
          }
        }

        &.current {
          span {
            display: inline-block;
            position: relative;
            &::after {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    .sns {
      margin-bottom: 30px;
      text-align: center;

      a {
        @include mq(pc) {
          margin: 0 5px;
        }
        @include mq(sp) {
          margin: 0 10px;
        }
        svg {
          @include mq(pc) {
            width: 25px;
            height: 25px;
          }
          @include mq(sp) {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .lang {
      position: relative;
      text-align: center;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        background: #000;
        transform: translate(-50%, -50%);
        @include mq(pc) {
          height: 11px;
        }
        @include mq(sp) {
          height: 14px;
          margin-left: -1px;
        }
      }
      a {
        margin: 0 4px;
        padding: 5px;
        svg {
          width: auto;
          @include mq(pc) {
            width: 20px;
            height: 11px;
          }
          @include mq(sp) {
            height: 14px;
          }
        }
        &.current {
          svg path {
            fill: $c_yellow;
          }
        }
      }
    }
  }
}
