/* ==========================================================================
   Animation
  ========================================================================== */

/* loading */
@keyframes loading {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
@keyframes arrFloat {
  0% {
    transform: translate(-50%, -10px);
  }
  70% {
    transform: translate(-50%, 0px);
  }
  100% {
    transform: translate(-50%, -10px);
  }
}

@keyframes bgGrad {
  0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}
