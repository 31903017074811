/* ==========================================================================
   Parts
  ========================================================================== */
svg {
  path {
    transition: .6s $Base;
  }
}

.hide {
  display: none !important;
}
.vh100 {
  height: 100vh;
}

.js-in {
  // transition: 1.8s
  transition-duration: 1.2s;
  transition-timing-function: $Base;
  transition-property: opacity, transform;
  opacity: 0;

  @include mq(pc) {
    transform: translateY(30px);
  }
  @include mq(sp) {
    transform: translateY(20px);
  }

  &.inview {
    opacity: 1;
    transform: translateY(0);
  }
}

/* link */
a {
  display: inline-block;
  outline: none;
  transition: .6s $Base;
  color: $fc_def;

  @include mq(pc) {
    &:hover {
    }
  }
  &:focus {
    outline: none;
  }
}


/* btn */
.btn {
  position: relative;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: 2px solid #000;
  font-weight: 700;

  @include mq(pc) {
    padding: 22px;
    border-radius: 36px;
    width: 320px;
    font-size: 18px;
  }
  @include mq(sp) {
    padding: 16px;
    border-radius: 28px;
    width: 100%;
    font-size: 17px;
  }
  .ico, .lbl {
    margin-right: 5px;
    vertical-align: middle;
  }

  &:hover {
    border: 2px solid $c_yellow;
    background: $c_yellow;
    color: #fff;
    svg path {
      fill: #fff;
    }
  }

  &.mdl {
    @include mq(pc) {
      padding: 15px;
      width: 300px;
      border-radius: 24px;
      font-size: 13px;
    }
    // @include mq(sp) {
    //   padding: 15px;
    //   border-radius: 25px;
    //   font-size: 17px;
    // }
  }
}


.nav_page {
  @include mq(pc) {
    display: flex;
    justify-content: center;
  }

  .btn {
    @include mq(pc) {
      margin: 0 12px;
      width: 180px;
      height: 140px;
    }
    @include mq(sp) {
      padding-top: 25px;
      margin-bottom: 10px;
      width: 100%;
      height: 70px;
      text-align: center;
      > * {
        vertical-align: middle;
      }
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }
    &::before {
      top: 1px;
      left: 1px;
      width: calc(100% - 2px);
      height: 2px;
    }
    &::after {
      right: 1px;
      bottom: 1px;
      border-top: 10px solid transparent;
    }

    .icon {
      display: inline-block;

      @include mq(pc) {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 42px;
        left: 50%;
        img {
          height: 24px;
          width: auto;
        }
      }
      @include mq(sp) {
        margin-right: 10px;
        img {
          height: 22px;
          width: auto;
        }
      }
    }
    .label {
      font-weight: 500;

      @include mq(pc) {
        position: relative;
        width: 100%;
        display: block;
        top: 88px;
        text-align: center;
        transform: translateY(-50%);
        line-height: 1.5;
      }
      @include mq(sp) {
        text-align: left;
        display: inline-block;
      }

      .min {
        @include mq(pc) {
          font-size: 80%;
        }
      }
    }

    &_c04 {
      @include mq(sp) {
        padding-top: 15px;
      }

      .label {
        @include mq(pc) {
          top: 92px;
        }
        @include mq(sp) {
          line-height: 1.3;
          font-weight: 400;
        }
      }
    }
    &.disabled {
      cursor: default;

      .label {
        color: #bababa;
      }
      &::before {
        background: #bababa;
      }
      &::after {
        border-right: 10px solid #bababa;
      }
    }
  }
}
.nav_back {
  text-align: center;

  .btn {
    > * {
      vertical-align: text-bottom;
    }
    .icon {
      @include mq(pc) {
        margin-right: 15px;
      }
      @include mq(sp) {
        margin-right: 10px;
      }

      img {
        width: 30px;
        vertical-align: middle;
      }
    }
    @include mq(pc) {
      margin: 0 auto;
      padding: 26px 10px;
      width: 380px;
      height: 70px;
    }
    @include mq(sp) {
      margin: 0 auto;
      padding: 18px 10px;
      width: 100%;
      height: 55px;
    }
  }
}

/* loading */
// .more {
//   display: block;
//   text-align: center;
//
//   &_loading {
//     animation: loading 1s infinite;
//   }
// }
