
/* ==========================================================================
   pages: Index
   ========================================================================== */

body.index {

  .kv {
    position: relative;

    .catch {
      @include mq(pc) {
        margin-top: 240px;
        margin-bottom: 125px;
      }
      @include mq(sp) {
        margin-top: 120px;
        margin-bottom: 38px;
      }

      span {
        text-align: center;
        display: block;
        overflow: hidden;

        @include mq(pc) {
          margin-bottom: 12px;
        }
        @include mq(sp) {
          margin-bottom: 6px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.catch_1 {
          svg {
            @include mq(sp) {
              width: auto;
              height: 22px;
            }
          }
        }
        &.catch_2 {
          svg {
            transition-delay: .1s;

            @include mq(sp) {
              width: auto;
              height: 19px;
            }
          }
        }
        svg {
          transition: 1.2s $Base;
          transform: translateY(110%);
        }
      }
      &.active {
        span {
          svg {
            transform: translateY(0);
          }
        }
      }
    }
    .scroll {
      position: absolute;
      right: 110px;
      bottom: -48vh;
      z-index: 10;
      animation: arrFloat 1.5s infinite;
    }
  }

  .imgs {
    position: relative;
    width: 100%;

    .img {
      position: relative;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .txt {
      position: relative;
      line-height: 1.5;

      @include mq(sp) {
        padding: 0 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.6;
      }
    }
    .img01 {
      @include mq(pc) {
        width: 64.1vw;
      }
    }
    .txt01 {
      transition-delay: .2s;

      @include mq(pc) {
        position: absolute;
        margin-top: -34.5vw;
        left: 60.4vw;
        font-size: 15px;
        font-weight: 700;
      }
      @include mq(sp) {
        margin: 30px 0 50px;
      }

      &.txt-jp {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.7;
      }
    }
    .img02 {
      @include mq(pc) {
        // width: 25.9vw;
        width: 24.5vw;
        margin-top: -5vw;
        left: 69.1vw;
      }
      @include mq(sp) {
        width: 26.7vw;
        left: 58.7vw;
      }
    }
    .txt02 {
      @include mq(pc) {
        position: absolute;
        margin-top: -7.3em;
        left: 14.1vw;
        font-size: 26px;
        font-weight: 700;
      }
      &.txt-jp {
        font-size: 30px;
        line-height: 1.6;
      }
    }
    .img03 {
      @include mq(pc) {
        width: 45.8vw;
        margin-top: 13.3vw;
        left: 42.9vw;
      }
      @include mq(sp) {
        width: 44.0vw;
        left: 20px;
        margin-top: -5.3vw;
      }
    }
    .img04 {
      @include mq(pc) {
        width: 27.5vw;
        margin-top: 3.0vw;
        left: 9vw;
      }
      @include mq(sp) {
        width: 32.0vw;
        left: calc(68vw - 20px);
        margin-top: -13vw;
      }
    }
    .img05 {
      @include mq(pc) {
        width: 50.8vw;
        margin-top: -13.7vw;
        left: 43.0vw;
      }
      @include mq(sp) {
        left: 20px;
        width: calc(100% - 40px);
        margin-top: 13.3vw;
      }
    }
    .img06 {
      @include mq(pc) {
        width: 23.3vw;
        margin-top: 9.1vw;
        left: 5.6vw;
      }
      @include mq(sp) {
        width: 29.3vw;
        left: 9.3vw;
        margin-top: 13.3vw;
      }
    }
    .img07 {
      @include mq(pc) {
        width: 25.4vw;
        margin-top: -8.3vw;
        left: 34.3vw;
      }
      @include mq(sp) {
        width: 33.3vw;
        left: 58.7vw;
        margin-top: -18.6vw;
      }
    }
    .img08 {
      @include mq(pc) {
        width: 28.8vw;
        margin-top: -39.6vw;
        left: 65.0vw;
      }
      @include mq(sp) {
        width: 37.3vw;
        left: 13.3vw;
        margin-top: -9.6vw;
      }
    }
    .img09 {
      @include mq(pc) {
        width: 43.3vw;
        margin-top: 31.0vw;
        left: 5.5vw;
      }
      @include mq(sp) {
        left: 20px;
        width: calc(100% - 40px);
        margin-top: 13.3vw;
      }
    }
    .txt03 {
      @include mq(pc) {
        position: absolute;
        margin-top: -23.4vw;
        left: 54.5vw;
        font-size: 26px;
        font-weight: 700;
      }
      @include mq(sp) {
        margin: 30px 0 0px;
      }
      &.txt-jp {
        line-height: 1.6;

        @include mq(pc) {
          font-size: 30px;
        }
        @include mq(sp) {
          font-size: 16px;
        }
      }
    }
  }

  .intro {

    @include mq(pc) {
      margin-top: 150px;
    }
    @include mq(sp) {
      margin-top: 50px;
    }

    .head {
      position: relative;
      @include bg_before(53.7vw);

      figure {
        position: relative;

        @include mq(pc) {
          padding-top: 80px;
          left: 70px;
          width: calc(100% - 140px);
        }
        @include mq(sp) {
          padding-top: 25px;
          left: 20px;
          width: calc(100% - 40px);
        }

        img {
          width: 100%;
          height: auto;
        }
      }
      h2.title {
        @include mq(pc) {
          margin: 70px auto 0;
          width: calc(100% - 260px);
        }
        @include mq(sp) {
          margin: 32px auto 0;
          width: calc(100% - 64px);
        }
        .num {
          display: inline-block;
          background: url(/assets/img/top/ico_wave.svg) no-repeat center bottom;

          @include mq(pc) {
            padding: 0 5px 18px;
            background-size: auto 12px;
          }
          @include mq(sp) {
            width: 32px;
            padding: 0 2px 8px;
            background-size: auto 6px;

            svg {
              width: 26px;
              height: auto;
            }
          }
        }
        .lbl {
          display: block;
          text-align: center;

          @include mq(pc) {
            margin-top: 95px;
          }
          @include mq(sp) {
            margin-top: 22px;
          }
        }
      }
    }
    .main {
      position: relative;

      p.lead,
      p.info {
        position: relative;

        @include mq(pc) {
          font-size: 26px;
          font-weight: 700;
          line-height: 1.5;
        }
        @include mq(sp) {
          padding: 0 20px;
          font-size: 16px;
          font-weight: 600;
          line-height: 1.6;
        }
        &.txt-jp {
          line-height: 1.5;

          @include mq(pc) {
            font-size: 28px;
          }
        }
      }
      figure.img {
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &#intro01 {
      h2.title {
        @include mq(pc) {
          margin-bottom: 195px;
        }
        @include mq(sp) {
          margin-bottom: 40px;

          .lbl {
            img {
              height: 30px;
              width: auto;
            }
          }
        }
      }
      .main {
        @include mq(pc) {
          .lead {
            position: absolute;
            left: 14.4vw;
          }
          .img10 {
            top: 0;
            left: 65.6vw;
            width: 28.3vw;
          }
          .img11 {
            margin-top: -12.5vw;
            left: 5.8vw;
            width: 52.5vw;
          }
          .img12 {
            margin-top: 12.5vw;
            left: 48.0vw;
            width: 45.8vw;
          }
          .img13 {
            margin-top: -20.8vw;
            left: 10.6vw;
            width: 30.0vw;
          }
        }
        @include mq(sp) {
          .lead {
            margin-bottom: 55px;
          }
          .img10 {
            top: 0;
            left: 66.7vw;
            width: 28.0vw;
          }
          .img11 {
            margin-top: -13.3vw;
            left: 20px;
            width: 53.3vw;
          }
          .img12 {
            margin-top: 9.3vw;
            left: 49.3vw;
            width: 45.3vw;
          }
          .img13 {
            margin-top: -22.6vw;
            left: 9.3vw;
            width: 30.6vw;
          }
        }
      }
    }
    &#intro02 {
      h2.title {
        @include mq(pc) {
          margin-bottom: 185px;

          .lbl {
            margin-top: 70px;
          }
        }
        @include mq(sp) {
          margin-bottom: 45px;

          .lbl {
            margin-top: -15px;
            img {
              height: 120px;
              width: auto;
            }
          }
        }
      }
      .main {
        @include mq(pc) {
          .lead {
            position: absolute;
            left: 48.5vw;
          }
          .img14 {
            top: 0;
            left: 5.8vw;
            width: 28.3vw;
          }
          .img15 {
            margin-top: -15.0vw;
            left: 41.5vw;
            width: 52.5vw;
          }
          .img16 {
            margin-top: 12.3vw;
            left: 5.8vw;
            width: 45.8vw;
          }
          .img17 {
            margin-top: -20.8vw;
            left: 59.0vw;
            width: 30.0vw;
          }
        }
        @include mq(sp) {
          .lead {
            margin-bottom: 55px;
          }
          .img14 {
            top: 0;
            left: 66.7vw;
            width: 28.0vw;
          }
          .img15 {
            margin-top: -13.3vw;
            left: 20px;
            width: 53.3vw;
          }
          .img16 {
            margin-top: 9.3vw;
            left: 49.3vw;
            width: 45.3vw;
          }
          .img17 {
            margin-top: -22.6vw;
            left: 9.3vw;
            width: 30.6vw;
          }
        }
      }
    }
    &#intro03 {
      @include mq(pc) {
        margin-bottom: 250px;
      }
      @include mq(sp) {
        margin-bottom: 100px;
      }

      h2.title {
        @include mq(pc) {
          margin-bottom: 135px;

          span.lbl {
            margin-top: 25px;
          }
        }
        @include mq(sp) {
          margin-bottom: 30px;

          .lbl {
            margin-top: -10px;
            img {
              height: 120px;
              width: auto;
            }
          }
        }
      }
      .main {
        @include mq(pc) {
          .lead {
            position: absolute;
            top: 1vw;
            left: 13.1vw;
          }
          .img18 {
            top: 0;
            left: 59.0vw;
            width: 26.2vw;
          }
          .img19 {
            margin-top: -1.1vw;
            left: 5.8vw;
            width: 35.4vw;
          }
          .img20 {
            margin-top: -14.5vw;
            left: 49.0vw;
            width: 45.0vw;
          }
          .info {
            margin: 9.5vw auto 0;
            width: 980px;
          }
        }
        @include mq(sp) {
          .lead {
            margin-bottom: 32px;
          }
          .img18 {
            top: 0;
            left: 61.3vw;
            width: 25.3vw;
          }
          .img19 {
            margin-top: -13.3vw;
            left: 20px;
            width: 40.0vw;
          }
          .img20 {
            margin-top: -5.3vw;
            left: 53.3vw;
            width: 41.3vw;
          }
          .info {
            margin: 50px 0 0;
          }
        }
      }
    }
  }
}



#dummyCener {
  position: fixed;
  display: block;
  top: 0px;
  left: 50%;
  width: 1px;
  height: 100vh;
  background: #f00;
  margin-left: 1px;
  z-index: 999;
}
