
/* ==========================================================================
   vendor
  ========================================================================== */

/* lazyload */
.limg {
  opacity: 0;
  transition: 1s $Base;
}
.lazyload {
}
.lazyloaded{
  opacity: 1;
}

/* swiper */
.swiper-container {
  // overflow: visible;

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    overflow: hidden;

    @include mq(sp) {
      display: none;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: inline-block;
      left: 50%;
      top: 50%;
      width: 10px;
      height: 10px;
      transform: translate(-50%,-50%);
      transition: .8s $Base;
    }
    &::before {
      opacity: 1;
    }
    &::after {
      opacity: 0;
    }
    &:hover {
      &::before {
        opacity: 0;
      }
      &::after {
        margin-left: 0;
        opacity: 1;
      }
    }
  }

  .swiper-button-prev {
    left: 15px;

    &::before {
      background: url(/en/assets/img/common/ico_arr2.svg) no-repeat center center;
      background-size: cover;
    }
    &::after {
      margin-left: 3em;
      background: url(/en/assets/img/common/ico_arr2_ov.svg) no-repeat center center;
      background-size: cover;
    }
    &:hover {
      &::before {
        margin-left: -3em;
      }
      &::after {
        margin-left: 0;
      }
    }
  }
  .swiper-button-next {
    right: 15px;
    &::before {
      background: url(/en/assets/img/common/ico_arr.svg) no-repeat center center;
      background-size: cover;
    }
    &::after {
      margin-left: -3em;
      background: url(/en/assets/img/common/ico_arr_ov.svg) no-repeat center center;
      background-size: cover;
    }
    &:hover {
      &::before {
        margin-left: 3em;
      }
      &::after {
        margin-left: 0em;
      }
    }
  }

  .swiper-pagination {
    position: relative;
    bottom: auto;
    @include mq(pc) {
      margin: 18px 0 0;
      text-align: right;
    }
    @include mq(sp) {
      margin: 13px 0 0;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: #e6e6e6;
      opacity: 1;

      @include mq(pc) {
        margin: 0 0 0 5px;
      }
      @include mq(sp) {
        margin: 0 3px;
      }
    }
    .swiper-pagination-bullet-active {
      background: $c_yellow;
    }
  }
}
