/* ==========================================================================
   frame
  ========================================================================== */

html,
body {
  height: 100%;
  width: 100%;
}

body {
  color: $fc_def;
  font-family: $ff_def;
  background-color: $bg_def;
  overflow-x: hidden;

  font-size: 14px;
  font-weight: 600;

  &.fixed {
    overflow: hidden;
  }

  &.jp,
  .jp {
    font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', sans-serif;
  }
  .en {
    font-family: 'Raleway', sans-serif;
  }
}


@include mq(pc) {
  .js-scroll-contents {
    // transition: .1s ease-in;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
  }
  .js-scroll-dummy {
    position: relative;
    width: 100%;
    opacity: 0;
    z-index: -1;
    height: 100vh;
  }
}

#wrap {
  width: 100%;
  opacity: 0;
  overflow: hidden;
  transition: .4s $Base;


  &.ready {
    opacity: 1;
  }
  &.fadeout {
    opacity: 0;
  }
}


.in {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  @include mq(pc) {
    min-width: 940px;
    max-width: 980px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &_480 {
    @include mq(pc) {
      width: 520px;
    }
  }
  &_800 {
    @include mq(pc) {
      max-width: 840px;
    }
  }
  &_1028 {
    @include mq(pc) {
      max-width: 1068px;
    }
    @include mq(sp) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  &_full {
    padding-left: 0;
    padding-right: 0;
  }
}
