/* ==========================================================================
   Footer
  ========================================================================== */

footer.global {
  .in {
    width: 100%;

    @include mq(pc) {
      display: flex;
      max-width: 1020px;
    }
    &:last-child {
      display: block;
      @include mq(pc) {
        padding-top: 100px;
      }
    }

    a:hover {
      svg {
        path {
          fill: $c_yellow;
        }
      }
    }

    .logo {
      @include mq(pc) {
        width: 98px;
        img {
          display: inline-block;
          width: 98px;
        }
      }
    }
    .nav {
      @include mq(pc) {
        flex: 1;
        display: flex;
        padding-left: 240px;
        padding-right: 40px;
        > * {
          width: calc(100% / 3);
        }
      }
      .global {
        a {
          display: block;
          margin-bottom: 22px;
          svg {
            height: 12px;
            width: auto;
          }
        }
      }
      .sns {
        @include mq(sp) {
          margin-bottom: 50px;
          text-align: center;
        }
        .label {
          display: block;
          margin-bottom: 20px;

          &.label_faq {
            margin-top: 26px;
            margin-bottom: 0;
          }
        }
        a {
          @include mq(pc) {
            margin-right: 10px;
          }
          @include mq(sp) {
            margin: 0 10px;
          }

          svg {
            @include mq(pc) {
              width: 30px;
              height: 30px;
            }
            @include mq(sp) {
              width: 35px;
              height: 35px;
            }
          }
        }
      }
      .other {
        @include mq(sp) {
          margin-bottom: 40px;
          text-align: center;
        }
        a {
          @include mq(pc) {
            display: block;
            margin-bottom: 22px;
          }
          @include mq(sp) {
            margin: 0 8px;
            svg {
              height: 9.5px;
              width: auto;
            }
          }
        }
      }
    }

    .address {
      @include mq(sp) {
        margin-bottom: 32px;
        text-align: center;
      }
      dt {
        font-weight: 700;
        @include mq(pc) {
          padding-bottom: 6px;
          font-size: 15px;
        }
        @include mq(sp) {
          padding-bottom: 15px;
          font-size: 14px;
        }
      }
      dd {
        font-size: 12px;
        line-height: 1.7;
      }
    }
    .totop {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
    .copyright {
      @include mq(pc) {
        position: absolute;
        display: inline-block;
        right: 0;
        bottom: 0;
      }
      @include mq(sp) {
        text-align: center;
      }
    }
  }
}
#fmap {
  display: block;
  width: 100%;
  background: #dedede;

  @include mq(pc) {
    margin-top: 70px;
    height: 540px;
  }
  @include mq(sp) {
    margin-top: 37px;
    height: 300px;
  }
}
